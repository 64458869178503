.client-managment-element {
  z-index: 2;
  display: flex;
  height: 100%;
  width: 30%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.client-managment-nav {
  display: flex;
  justify-content: space-around;
  width: 70%;
}
.client-managment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  cursor: pointer;
}

.client-managment-button:hover {
  border-top: 1px solid rgba(90, 90, 90, 0.74);
  border-bottom: none;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  color: rgb(44, 190, 71);
  -webkit-animation: shadow-drop-2-top-green 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-top-green 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.client-managment-element:hover {
  border-top: 1px solid rgba(90, 90, 90, 0.74);
  border-bottom: none;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  color: rgb(255, 255, 255);
  -webkit-animation: shadow-drop-2-top-blue 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-top-blue 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.active {
  border-top: 1px solid rgba(90, 90, 90, 0.74);
  border-bottom: none;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  color: rgb(113, 183, 240) !important;
  -webkit-animation: shadow-drop-2-top-blue 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-top-blue 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  cursor: default;
}
