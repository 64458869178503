.App {
  height: 100%;
  text-align: center;
  background-color: #1f1f1f;
  position: relative;
  overflow: hidden;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  background-color: rgba(240, 248, 255, 0);
  color: darkgrey;
  width: 100%;
  max-width: 400px;
  border: none;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

input:focus,
input:active {
  border-bottom: 1px solid rgb(113, 183, 240);
  border-right: 1px solid #71b7f0;
  outline: none;
}

.app-container {
  height: fit-content;
  z-index: 3;
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75%;
  justify-content: center;
}

.header {
  position: relative;
}

#hex-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  overflow: hidden;
}

.shadow-drop-center {
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes shadow-drop-center {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  }
}

@keyframes pulsate-bck {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.text {
  color: white;
  text-decoration: none;
  font-family: "BraveEighty";
  font-size: 30px;
}

.glass-effect {
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.neomorphism-button {
  background-color: #1f1f1f;
  border: none;
  border-radius: 15px;
  box-shadow: 6px 6px 12px #0e0e0e, -6px -6px 12px #303030;
  color: #71b7f0;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  text-shadow: 0px 0px 10px #71b7f0;
}

.neomorphism-button:active {
  box-shadow: inset 6px 6px 12px #0e0e0e, inset -6px -6px 12px #303030;
}

.neomorphism-button-logout {
  color: #851919;
  background-color: #1f1f1f;
  border: none;
  border-radius: 15px;
  box-shadow: 6px 6px 12px #0e0e0e, -6px -6px 12px #ff00007a;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.neomorphism-button-logout:active {
  box-shadow: inset 6px 6px 12px #0e0e0e, inset -6px -6px 12px #ff00007a;
}

.flicker-1 {
  -webkit-animation: flicker-1 2s linear infinite both;
  animation: flicker-1 2s linear infinite both;
}
@-webkit-keyframes flicker-1 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  47.99% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  49.01% {
    opacity: 1;
  }
}
@keyframes flicker-1 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  47.99% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  49.01% {
    opacity: 1;
  }
}
.privacy-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.privacy-menu h3 {
  font-size: 18px;
}

.privacy-link {
  color: rgb(136, 136, 136);
  text-decoration: none;
}
.privacy-link:hover {
  color: white;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .privacy-menu h3 {
    font-size: 14px !important;
  }
  .privacy-menu h1 {
    font-size: 28px !important;
  }
}
@media only screen and (max-width: 320px) {
  .privacy-menu h3 {
    font-size: 12px !important;
  }
  .privacy-menu h1 {
    font-size: 22px !important;
  }
}
.shadow-inset-center-white {
  -webkit-animation: shadow-inset-center-white 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-inset-center-white 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

@-webkit-keyframes shadow-inset-center-white {
  0% {
    -webkit-box-shadow: inset 0 0 0 0#ffffff91;
    box-shadow: inset 0 0 0 0 #ffffff91;
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0px #ffffff91;
    box-shadow: inset 0 0 20px 0 #ffffff91;
  }
}
@keyframes shadow-inset-center-white {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 #ffffff91;
    box-shadow: inset 0 0 0 0 #ffffff91;
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0px #ffffff91;
    box-shadow: inset 0 0 20px 0 #ffffff91;
  }
}

.shadow-inset-center {
  -webkit-animation: shadow-inset-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-inset-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes shadow-inset-center {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-inset-center {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.35);
  }
}
.shadow-inset-bl {
  -webkit-animation: shadow-inset-bl 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: shadow-inset-bl 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes shadow-inset-bl {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 6px -6px 14px -6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 6px -6px 14px -6px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-inset-bl {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 6px -6px 14px -6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 6px -6px 14px -6px rgba(0, 0, 0, 0.35);
  }
}

.shadow-drop-bl {
  -webkit-animation: shadow-drop-bl 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: shadow-drop-bl 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes shadow-drop-bl {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: -7px 8px 104x -7px rgba(0, 0, 0, 0.3);
    box-shadow: -7px 8px 20px -7px rgba(0, 0, 0, 0.3);
  }
}
@keyframes shadow-drop-bl {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: -7px 10px 14px -7px rgba(0, 0, 0, 0.3);
    box-shadow: -7px 10px 20px -7px rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.shadow-drop-br {
  -webkit-animation: shadow-drop-br 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: shadow-drop-br 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes shadow-drop-br {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
    box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-br {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
    box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

@keyframes shadow-drop-2-top-blue {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateY(px);
    transform: translateZ(50px) translateY(6px);
    -webkit-box-shadow: 0 -12px 20px -12px rgba(113, 183, 240, 0.5);
    box-shadow: 0 -12px 20px -12px rgba(113, 183, 240, 0.5);
  }
}

@keyframes shadow-drop-2-top-blue {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateY(6px);
    transform: translateZ(50px) translateY(6px);
    -webkit-box-shadow: 0 -12px 20px -12px rgba(113, 183, 240, 0.5);
    box-shadow: 0 -12px 20px -12px rgba(113, 183, 240, 0.5);
  }
}

@keyframes shadow-drop-2-top-green {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateY(px);
    transform: translateZ(50px) translateY(6px);
    -webkit-box-shadow: 0 -12px 20px -12px rgba(44, 190, 71, 0.5);
    box-shadow: 0 -12px 20px -12px rgba(44, 190, 71, 0.5);
  }
}

@keyframes shadow-drop-2-top-green {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateY(6px);
    transform: translateZ(50px) translateY(6px);
    -webkit-box-shadow: 0 -12px 20px -12px rgba(44, 190, 71, 0.5);
    box-shadow: 0 -12px 20px -12px rgba(44, 190, 71, 0.5);
  }
}
@-webkit-keyframes shadow-drop-2-center-red {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(248, 8, 8, 0.76);
    box-shadow: 0 0 20px 0px rgba(248, 8, 8, 0.76);
  }
}
@keyframes shadow-drop-2-center-red {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(248, 8, 8, 0.76);
    box-shadow: 0 0 20px 0px rgba(248, 8, 8, 0.76);
  }
}
@-webkit-keyframes shadow-drop-2-center-white {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(236, 236, 236, 0.692);
    box-shadow: 0 0 20px 0px rgba(236, 236, 236, 0.692);
  }
}
@keyframes shadow-drop-2-center-white {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(236, 236, 236, 0.692);
    box-shadow: 0 0 20px 0px rgba(236, 236, 236, 0.692);
  }
}
