.pie-chart {
  height: 100px;
  border-radius: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.legend {
  margin-left: 10%;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.legend div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.legend span {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
}

.legend-success {
  background-color: #71b7f0;
}

.legend-failed {
  background-color: #ff2d2d;
}
.pie-chart-center {
  width: 80px;
  display: flex;
  height: 80px;
  background-color: #1f1f1f;
  border-radius: 50%;
  position: absolute;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}
.pie-chart-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend-pending {
  background-color: #c4c4c4;
}
