.form-info-box {
  display: flex;
  flex-direction: column;
}
.form-info-label {
  padding-left: 1%;
  padding-bottom: 1%;
  width: fit-content;
  border-left: 1px solid rgb(113, 183, 240);
  display: flex;
  font-size: 22px;
  color: rgb(113, 183, 240);
}
.form-info-text {
  padding-left: 1%;
  padding-bottom: 1%;
  display: flex;
  color: white;
  width: fit-content;
  border-bottom: 1px solid rgb(113, 183, 240);
  border-left: 1px solid rgb(113, 183, 240);
}
