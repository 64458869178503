.error-container {
  width: 450px;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 25px;
  font-size: 22px;
  display: flex;
  flex-direction: column; /* Assicurati che il layout interno sia in colonna */
  justify-content: center;
}

.error-component {
  height: 77.1vh;
  width: 100vw;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
}
