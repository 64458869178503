.footer {
  padding: 1% 2% 1% 2%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 450px;
  background-color: #1f1f1f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: 3;
  color: #ffffff;
  height: 10%;
  font-size: 22px;
  align-items: center;
}
.footer-button {
  margin-right: 5%;
  height: 30px;
  width: 30px;
}
.footer-button:hover {
  cursor: pointer;
  color: white;
  -webkit-animation: shadow-drop-2-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.pulsate-bck {
  -webkit-animation: pulsate-bck 1s ease-in-out infinite both;
  animation: pulsate-bck 1s ease-in-out infinite both;
}
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media only screen and (max-width: 1440px) {
  .footer {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 488px) {
  .footer {
    width: 70%;
  }
}

@media only screen and (max-width: 375px) {
  .footer {
    font-size: 0.7rem;
  }
}
