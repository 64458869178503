.header {
  padding: 1% 2% 1% 2%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 450px;
  background-color: #1f1f1f;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 1;
  height: 15%;
}

.logo:hover {
  cursor: pointer;
}

.text-1 {
  width: 152px !important;
  margin-right: 3%;
}

.text-2 {
  margin-left: 5%;
  width: 152px !important;
  letter-spacing: 2px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media only screen and (max-width: 488px) {
  .text-1,
  .text-2 {
    display: none;
  }

  .header {
    width: 120px;
  }

  .logo {
    padding-top: 5%;
    padding-bottom: 5%;
  }
}
