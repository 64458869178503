.data-info-box {
  display: flex;
  border-left: 1px solid #71b7f0;
  border-bottom: 1px solid rgb(113, 183, 240);
  flex-direction: row;
  align-items: center;
  width: 85%;
  justify-content: space-between;
}
.data-info-label {
  padding-left: 1%;
  padding-bottom: 1%;
  width: fit-content;
  display: flex;
  font-size: 18px;
  color: rgb(113, 183, 240);
}
.data-info-text {
  padding-left: 1%;
  padding-bottom: 1%;
  display: flex;
  color: white;
  font-size: 18px;
  width: fit-content;
}
