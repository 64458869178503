.privacy-container {
  width: 80%;
  height: 90%;
  margin-bottom: 5%;
  padding: 5%;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
}

.privacy-component {
  height: fit-content;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.privacy-container h1 {
  text-decoration: underline;
  margin-bottom: 5%;
}

.privacy-main-text {
  text-align: center;
  margin-bottom: 2%;
}

.colored {
  color: #71b7f0;
}

.subtitle {
  text-decoration: underline;
}

.secondary-text {
  text-align: start;
  margin-top: 5%;
}
.emphasis {
  text-decoration: underline;
  margin-top: 5%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 425px) {
  .privacy-container {
    width: 95%;
  }
  .privacy-container p,
  .privacy-main-text {
    font-size: 16px;
  }
  .privacy-container h3 {
    font-size: 22px;
  }
}
