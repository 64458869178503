.container {
  background: #1f1f1f;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  height: 100%;
}

#hex-grid {
  background: #1f1f1f;
  height: 100%;
}

#hex-grid .grid {
  position: absolute;
  top: 0;
  left: 0;
  background: url(./grid-no-border.svg) repeat;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: 500px;
}

#hex-grid .light {
  position: relative;
  transform: translate(-50%, -50%);
  width: 10em;
  height: 110%;
  filter: blur(15px);
  background: linear-gradient(90deg, #0cbcf8 0%, #044480 100%);
  z-index: 0;
  animation: move 8s linear infinite;
}

@keyframes move {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(100vw);
  }
}

@media only screen and (max-width: 1024px) {
  #hex-grid .light {
    width: 4em;
    animation: move 6s linear infinite;
  }
}
