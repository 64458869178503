.recovery-container {
  flex-direction: column;
  border-radius: 25px;
  width: 450px;
  padding: 2%;
  padding-left: 2%;
  padding-right: 2%;
}
.recovery-component {
  height: 77.1vh;
  width: 100vw;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
}

.input-container {
  margin-top: 10%;
}

.recovery-button {
  margin-top: 5%;
  max-width: 400px;
  width: auto;
}

recovery-label {
  color: #ffffff;
  font-size: 22px;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  font-size: 18px;
  max-width: 300px;
}

select {
  margin-top: 3% !important;
}

@media only screen and (max-width: 588px) {
  .recovery-container {
    width: 80%;
    padding-top: 10%;
    padding-bottom: 10%;
  }
  input {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 1440px) {
  .recovery-component {
    height: 81vh;
  }
}

@media only screen and (max-width: 1024px) {
  .recovery-component {
    height: 76.2vh;
  }
}
@media only screen and (max-width: 768px) {
  .recovery-component {
    height: 77vh;
  }
}
