.ext-login-component {
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ext-login-container {
  width: 450px;
  margin-top: 5%;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 25px;
  font-size: 22px;
  display: flex;
  flex-direction: column; /* Assicurati che il layout interno sia in colonna */
  justify-content: center;
}

.ext-login-button {
  margin-top: 15%;
  max-width: 400px;
  width: auto;
}

@media only screen and (max-width: 1440px) {
  .ext-login-component {
    height: 81vh;
  }
}

@media only screen and (max-width: 1024px) {
  .ext-login-component {
    height: 76.2vh;
  }
}
@media only screen and (max-width: 768px) {
  .ext-login-component {
    height: 77vh;
  }
}
@media only screen and (max-width: 488px) {
  .ext-login-container {
    width: 80%;
    padding-top: 8%;
    padding-bottom: 8%;
  }
}
