.login-component {
  height: 77.1vh;
  width: 100vw;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 450px;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 25px;
  font-size: 22px;
  display: flex;
  flex-direction: column; /* Assicurati che il layout interno sia in colonna */
  justify-content: center;
}

.login-button {
  margin-top: 5%;
  max-width: 400px;
  width: auto;
}

.recovery-text {
  margin-top: 7%;
  margin-bottom: 2%;
  font-size: 15px;
  text-align: center; /* Centra il testo */
}

.password-wrapper {
  position: relative;
  width: 100%;
}

.password-input {
  padding-right: 40px;
  width: 100%;
  box-sizing: border-box; /* Mantiene la dimensione fissa */
}

.toggle-password-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%); /* Centra verticalmente il pulsante */
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  outline: none;
  color: #007bff;
  padding: 0;
}

.login-label {
  color: #71b7f0;
}

.error-message {
  color: rgb(204, 54, 54);
  margin-bottom: 2%;
}

.recovery-link {
  text-decoration: none;
  color: #ffffff;
}

.recovery-link:hover {
  color: #71b7f0;
  text-decoration: underline;
}

.recovery-text:hover {
  cursor: pointer;
  animation: pulsate-bck 0.8s ease-in-out infinite both;
}

@media only screen and (max-width: 1440px) {
  .login-component {
    height: 81vh;
  }
}

@media only screen and (max-width: 1024px) {
  .login-component {
    height: 76.2vh;
  }
}
@media only screen and (max-width: 768px) {
  .login-component {
    height: 77vh;
  }
}
@media only screen and (max-width: 488px) {
  .login-container {
    width: 80%;
    padding-top: 8%;
    padding-bottom: 8%;
  }

  label {
    font-size: 18px;
  }

  input,
  .password-wrapper {
    width: 80%;
    margin: 0 auto;
  }
}
