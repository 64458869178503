.scheduled-card h2 {
  color: #71b7f0;
  font-size: 18px;
}

.scheduled-card {
  display: flex;
  font-size: 18px;
  justify-content: space-around;
}

.scheduled-list {
  margin-top: 1%;
  display: flex;
  flex-direction: column;
}
.scheduled-field {
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 25%;
}
.scheduled-row:hover {
  border-left: 1px solid #71b7f0;
  border-bottom: 1px solid #71b7f0;
  color:#71b7f0;
}
.scheduled-row {
  display: flex;
  width: 103%;
  justify-content: space-around;
}
.scheduled-header {
  display: flex;
  font-size: 22px;
  flex-direction: row;
  justify-content: center;
  width: 25%;
}

.pencil-img {
  margin-left: 10px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}
