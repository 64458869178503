.home-component {
  border-radius: 25px;
  margin-top: 1%;
  margin-bottom: 1%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 20px solid #1f1f1f;
}

.home-component__border {
  display: flex;
  width: 93vw;
  height: 88vh;
  border-radius: 12.5px;
}

.home-component__left-board {
  border-top-left-radius: 12.5px;
  border-bottom-left-radius: 12.5px;
  position: relative;
  height: 100%;
  display: flex;
  width: 22.5%;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  background: #1f1f1f;
}

.home-component__right-board {
  border-top-right-radius: 12.5px;
  border-bottom-right-radius: 12.5px;
  position: relative;
  width: 77%;
  z-index: 1;
}

.home-component__separator {
  width: 1vw;
  background: #1f1f1f;
  z-index: 2;
}

.home-component__user-info-border {
  position: relative;
  float: right;
  width: 13%;
  background-color: #1f1f1f;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  border-bottom-left-radius: 25px;
}

.home-component__user-info-inner {
  z-index: 2;
  background-color: #1f1f1f;
  height: 86%;
  width: 89%;
  border-bottom-left-radius: 12.5px;
  border-top-right-radius: 12.5px;
  display: flex;
  align-items: center;
}

.profile-container {
  margin-left: 4%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-component__button-container {
  margin-left: 6%;
  display: flex;
  width: 32.5px;
  height: 32.5px;
  flex-direction: column;
  justify-content: center;
}

.home-component__button-containerr img {
  object-fit: cover;
}

.first-row {
  height: 10%;
  width: 100%;
  border: solid 1px rgb(30, 107, 36);
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  border-top-left-radius: 12.5px;
  background-color: #0b250e3d;
  align-content: center;
  justify-content: center;
}

.row {
  height: 10%;
  width: 100%;
  border: solid 1px #1e4463;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #1a263870;
  align-content: center;
  justify-content: center;
}
.selected-row {
  background-color: #29508dd7 !important;
  border-color: #ffffff !important ;
}
.row-filled {
  height: 10%;
  width: 100%;
  border: solid 1px #717271;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #3535353d;
  align-content: center;
  justify-content: center;
  z-index: 4;
}

.row-filled:hover {
  background-color: #6666663d;
  border-color: #9a9b9a;
}

.row:hover {
  background-color: #344d7285;
  border-color: #3170a3;
}

.toolbar {
  display: flex;
  height: 15%;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.client-managment-container {
  z-index: 3;
  width: 85%;
  border-bottom-right-radius: 25px;
  background-color: #1f1f1f;
}
.pulsate:hover {
  -webkit-animation: pulsate-bck 1.5s ease-in-out infinite both;
  animation: pulsate-bck 1.5s ease-in-out infinite both;
}

.row img {
  width: 70px;
  height: 70px;
}

.login-modal {
  display: flex;
  justify-content: center;
}

.login-modal-content {
  z-index: 6;
  position: absolute;
  padding: 2%;
  background-color: #1f1f1f;
  border-radius: 25px;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease, background-color 0.5s ease;
}
.menu-overlay.menu-open {
  color: #71b7f0;
  opacity: 1;
  display: flex;
  background-color: rgba(0, 0, 0, 0.795);
  pointer-events: auto;
  z-index: 5;
  justify-content: center;
  align-items: center;
}
.client-managment-container {
  z-index: 1;
  background-color: #1f1f1f;
  border-bottom-right-radius: 12.5px;
  display: flex;
  justify-content: space-evenly;
}

.client-managment-content {
  display: flex;
  color: white;
  height: 85%;
  /* width: 100%; */
  flex-direction: column;
}
.pulsate {
  cursor: pointer;
}
