.kie-container {
  width: 80%;
  height: 90%;
  padding: 5%;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
}

.cookie-component {
  margin-top: 1%;
  height: 78.6vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1735px) {
  .cookie-component {
    height: fit-content;
  }
}
@media screen and (max-width: 768px) {
  .kie-container p {
    font-size: 23px;
  }
}
@media screen and (max-width: 320px) {
  .kie-container p {
    font-size: 16px;
  }
  .kie-container h3 {
    font-size: 22px;
  }
}
