.client-info-data {
  margin-left: 2%;
  display: flex;
  height: 100%;
  padding-top: 8%;
  width: 50%;
  flex-direction: column;
}

.client-info-container {
  display: flex;
  height: 100%;
}

.data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 50%;
}

.data-container__info {
  padding-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-around;
}

.client-logout {
  border-radius: 25px;
  padding: 15%;
  font-size: 18px;
  width: 150px;
  cursor: pointer;
  background: rgb(31, 31, 31);
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  color: #f60202;
}
.client-logout:hover {
  border: 0.5px solid #f602029d;
  color: #f60202;
  -webkit-animation: shadow-drop-2-center-red 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-center-red 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}
.data-container__button {
  display: flex;
}
.data-container__buttons {
  display: flex;
  width: 75%;
  height: 40%;
  justify-content: flex-end;
  align-items: flex-end;
}

.data-container__data {
  width: 75%;
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
