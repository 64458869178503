.overlay-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-overlay-schedule {
  margin-top: -67%;
    position: absolute;
    z-index: 21;
    width: 100%;
    height: 150%;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease, background-color 0.5s ease;
  }
  .menu-overlay-schedule.menu-open {
    color: #71b7f0;
    opacity: 1;
    display: flex;
    background-color: rgba(0, 0, 0, 0.795);
    pointer-events: auto;
    z-index: 5;
    justify-content: center;
    align-items: center;
  }