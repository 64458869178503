.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.loader-overlay {
  position: fixed; /* Copre tutta la finestra */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000; /* Assicura che sia sopra tutto */
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all; /* Blocca i click */
}

.loader {
  margin-top: 50px;
  position: absolute;
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.1);
  transform-origin: 50% 50%;
  transform: perspective(200px) rotateX(66deg);
  animation: spinner-wiggle 1.2s infinite;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid #0000;
  animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    spinner-fade 1.2s linear infinite;
}
.loader:before {
  border-top-color: #fff;
}
.loader:after {
  border-top-color: #0cbcf8;
  animation-delay: 0.4s;
}

@keyframes spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-fade {
  25%,
  75% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-inner {
  width: 68px;
  height: 68px;
  margin: auto;
  position: absolute;
  margin-bottom: 20px;
}
.loader-inner:before {
  content: "";
  width: 48px;
  height: 5px;
  background: #000;
  opacity: 0.25;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}
.loader-inner:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #8d8d8d;
  animation: bxSpin 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}
@keyframes bxSpin {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow {
  0%,
  100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}
.loader-lock {
  width: 60px;
  height: 30px;
  position: relative;
  border: 5px solid #fff;
  border-radius: 8px;
}
.loader-lock::before {
  content: "";
  position: absolute;
  border: 5px solid #fff;
  width: 32px;
  height: 28px;
  border-radius: 50% 50% 0 0;
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%);
}
.loader-lock::after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 16px 0 #fff, -16px 0 #fff;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 16px 0 rgba(255, 255, 255, 0.25), -16px 0 rgba(255, 255, 255, 1);
  }
  50% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 16px 0 rgba(255, 255, 255, 0.25),
      -16px 0 rgba(255, 255, 255, 0.25);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 16px 0 rgba(255, 255, 255, 1), -16px 0 rgba(255, 255, 255, 0.25);
  }
}
